import { SET_USERINFO, SET_UNAUTHORIZED, LOGOUT, SET_HORARY, SET_CALIFICATIONS, SET_CALIFICATIONS_HAS_CHANGES } from './../action/userAction';
import { DAYS } from "./../../services/constantsService";

const makeHorary = (data) => {
    if (!(data)) { return null }
    let d = new Date();
    let hdict = {};
    (data || []).forEach(h => hdict.hasOwnProperty(h.DIA_SEMANA) ? hdict[h.DIA_SEMANA].push(h) : hdict[h.DIA_SEMANA] = [h]);
    let today = hdict[DAYS[d.getDay()].toUpperCase()] || [];
    let pending = today.filter(i => {
        let time = i.HORA_FIN.split(":");
        return (!(Number(time[0]) < d.getHours() || (Number(time[0]) === d.getHours() && Number(time[1]) <= d.getMinutes())));
    })
    return {
        all: hdict,
        today,
        pending,
        progress: today.length - pending.length
    }
}

const makeCalifications = (data) => {
    try {
        if (!(data)) { if (data === false) { return false } return null }
        let dict = {};
        let onlyCals = data?.calificaciones?.filter(c => {
            if (!!(c.EVALUACION !== "HAB" && c.EVALUACION !== "ACUMULADO")) {
                dict.hasOwnProperty(c.NOMBRE_ASIGNATURA) ? dict[c.NOMBRE_ASIGNATURA].push(c) : dict[c.NOMBRE_ASIGNATURA] = [c];
                return true;
            }
            return false;
        });
        return {
            all: dict,
            total: onlyCals.length,
            done: onlyCals.filter(c => !!(c.CALIFICACION !== null)).length,
            hasChanges: !!(data.cambios),
            lastUpdated: new Date(data.marc_temp * 1000)
        }
    } catch (err) {
        return {};
    }
}

const horaryLoaded = localStorage.getItem("portal/horary") !== null;
const calificationsLoaded = localStorage.getItem("portal/califications") !== null;

const defaultState = {
    userInfo: JSON.parse(localStorage.getItem("portal/user")) || null,
    unauthorized: false
};

try {
    defaultState.horary = horaryLoaded ? makeHorary(JSON.parse(localStorage.getItem("portal/horary"))) : null;
    defaultState.califications = calificationsLoaded && makeCalifications(JSON.parse(localStorage.getItem("portal/califications")));
} catch (error) {
    defaultState.horary = null;
    defaultState.califications = null;
}

const closeSesion = () => {
    localStorage.removeItem("portal/user")
    localStorage.removeItem("portal/token")
    localStorage.removeItem("portal/gtaccess") //Google access Token
    localStorage.removeItem("portal/oneToken") //Google one tap Token
    localStorage.removeItem("portal/horary")
    localStorage.removeItem("portal/califications")
    localStorage.removeItem("portal/campaign")
}

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_USERINFO:
            localStorage.setItem("portal/user", JSON.stringify(action.payload))
            return {
                ...state,
                userInfo: action.payload
            };
        case LOGOUT:
            closeSesion()
            if (!!(action.payload)) {
                // window.open(`https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=${action.payload}`, "_self")
                let a = window.open(`https://www.google.com/accounts/Logout`, "_blank");
            }
            // window.location.reload();
            return {
                ...state,
                userInfo: null
            };
        case SET_UNAUTHORIZED:
            closeSesion()
            return {
                ...state,
                userInfo: null,
                unauthorized: !!(action.payload)
            }
        case SET_CALIFICATIONS:
            return {
                ...state,
                califications: makeCalifications(action.payload),
            }
        case SET_CALIFICATIONS_HAS_CHANGES:
            return {
                ...state,
                califications: { ...state.califications, hasChanges: action.payload },
            }
        case SET_HORARY:
            return {
                ...state,
                horary: makeHorary(action.payload),
                horaryLoaded: true
            }
        default:
            return { ...state };
    }
}

export default reducer;