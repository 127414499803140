import { AXIOS_REQUEST } from "../../services/axiosService";
import { MYHORARY, CALIFICATIONS } from "../../services/endPointService";

export const SET_USERINFO = "SET_USERINFO";
export const SET_UNAUTHORIZED = "SET_UNAUTHORIZED";
export const LOGOUT = "LOGOUT";
export const SET_HORARY = "SET_HORARY";
export const SET_CALIFICATIONS = "SET_CALIFICATIONS";
export const SET_CALIFICATIONS_HAS_CHANGES = "SET_CALIFICATIONS_HAS_CHANGES";

export const setUserInfo = payload => ({ payload, type: SET_USERINFO });
export const setHorary = (payload) => ({ payload, type: SET_HORARY });
export const setCalifications = (payload) => ({ payload, type: SET_CALIFICATIONS });
export const setCalificationsHasChanges = (payload) => ({ payload, type: SET_CALIFICATIONS_HAS_CHANGES });


export const logout = (closeGoogle = false) => {
    let gToken = localStorage.getItem("portal/gtaccess");
    let ds = { payload: closeGoogle && window.location.origin, type: LOGOUT };
    if (!!(gToken)) {
        return dispatch => AXIOS_REQUEST(`https://oauth2.googleapis.com/revoke?token=${gToken}`, "POST", null, false).then(resp => dispatch(ds))
    }
    try {
        document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    } catch (error) {}

    return ds

    // if (!!(gToken)) {
    //     return dispatch => {
    //         let loc = window.location.origin;
    //         window.open(`https://www.google.com/accounts/Logout?continue=${loc}`, "_self")
    //         // return dispatch => AXIOS_REQUEST(`https://oauth2.googleapis.com/revoke?token=${gToken}`, "POST", null, false).then(resp => dispatch({ type: LOGOUT }))
    //         // return dispatch => AXIOS_REQUEST(`https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout`).then(resp => {
    //         //     console.log(resp)
    //         //     debugger
    //         // return dispatch({ type: LOGOUT })})
    //     }
    // }
};

// Corregir error de la cuenta: aulasedu@curnvirtual.edu.co ; pass: h8L=yN3n
export const setUnauthorized = (payload) => {
    let gToken = localStorage.getItem("portal/gtaccess");

    if (!!(gToken)) {
        return dispatch => AXIOS_REQUEST(`https://oauth2.googleapis.com/revoke?token=${gToken}`, "POST", null, false).then(resp => dispatch({ payload, type: SET_UNAUTHORIZED }))
    }
    return ({ payload, type: SET_UNAUTHORIZED })
};

export const getHorary = () => (dispatch) => {
    if (localStorage.getItem("portal/horary")) {
        let data = JSON.parse(localStorage.getItem("portal/horary"));
        dispatch(setHorary(data));
        return new Promise((resolve) => resolve(data));
    }
    return AXIOS_REQUEST(MYHORARY)
        .then(resp => {
            dispatch(setHorary(resp.data.horario));
            localStorage.setItem("portal/horary", JSON.stringify(resp.data.horario))
            return resp.data.horario;
        })
        .catch(err => err)

}

export const getCalifications = (force = false) => (dispatch) => {
    if (localStorage.getItem("portal/califications") && !(force)) {
        let data = JSON.parse(localStorage.getItem("portal/califications"));
        dispatch(setCalifications(data));
        return new Promise((resolve) => resolve(data));
    }
    return AXIOS_REQUEST(CALIFICATIONS)
        .then(resp => {
            let d = !!(resp.data.calificaciones) ? resp.data : null;
            dispatch(setCalifications(d));
            localStorage.setItem("portal/califications", JSON.stringify(d))
            return d;
        })
        .catch(err => err)

}