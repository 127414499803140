import React, { Component } from 'react';
import { WifiOff } from 'react-bootstrap-icons';
import { AXIOS_REQUEST } from '../../services/axiosService';
import { ERROR_REPORTING_URL } from '../../services/endPointService';

let timerValue = 1;

export const sendReport = (_data, successCallback = () => { }, errorCallback = () => { }, progressCallback) => {
    if (!(/^http[s]?:\/\/localhost.*$/.test(window.location.href))) {
        _data.device = navigator?.userAgent;

        let data = {
            app: "Portal Uninunez",
            msgerror: JSON.stringify(_data),
            user: localStorage.getItem("portal/user") || "WITHOUT USER INFO"
        }

        let lr = sessionStorage.getItem("NG_lastReported");
        let send = false;
        if (!lr) {
            send = true;
        } else {
            lr = new Date(Number(lr));
            let today = new Date();
            if (today.getMinutes() - lr.getMinutes() > 3 || lr.getDate() !== today.getDate()) {
                send = true;
            }
        }
        if (send) {
            return AXIOS_REQUEST(null, "post", data, false, ERROR_REPORTING_URL, progressCallback).then(() => {
                sessionStorage.setItem("NG_lastReported", new Date().getTime())
                return successCallback()
            }).catch(err => {
                return errorCallback()
            })
        } else {
            return successCallback();
        }
    }
}

class ErrorHandler extends Component {
    constructor(props) {
        console.log("NG")
        super(props);
        this.state = { hasError: false, error: null, timer: null, loading: null };
    }

    static getDerivedStateFromError(error) {
        // Actualiza el estado para que el siguiente renderizado muestre la interfaz de repuesto
        return { hasError: true, error };
    }

    reloadChildren = () => { window.location.reload() }

    componentDidCatch(error, errorInfo) {
        // Registrar el error en un servicio de reporte de errores
        // logErrorToMyService(error, errorInfo);

        console.log("========ERROR========");
        console.log({ error, errorInfo });
        console.log("=====================");
        if ((error?.message && /Loading [A-Z\s]*chunk [\d]+ failed/ig.test(error.message))
            || (error?.stack && /Loading [A-Z]*chunk [\d]+ failed/ig.test(error.stack))) {
            console.log("chunk failed");
            window.location.reload(true);
        } else {
            this.setState({ error, loading: 1 });

            let data = {
                error,
                stack: error.stack || "NG",
                errorInfo,
                path: window.location.href
            }
 
            sendReport(data, this.reloadWithTimer, this.reloadWithTimer, (progress) => {
                this.setState({ loading: Math.round((progress.loaded * 100) / progress.total) })
            })
        }
    }

    reloadWithTimer = () => {
        this.setState({ timer: timerValue, loading: null })
        let interval = setInterval(() => {
            if (this.state.timer > 0) {
                this.setState({ timer: this.state.timer - 1 })
            } else {
                clearInterval(interval)
                if (this.state.error?.message && /Loading chunk [\d]+ failed/ig.test(this.state.error.message)) {
                    window.location.reload(true);
                } else {
                    this.setState({ hasError: false, error: null, timer: null, loading: null });
                    timerValue = (timerValue * 2 < 60) ? timerValue * 2 : 60;
                }
            }
        }, 1000)
    }

    render() {
        if (this.state.hasError) {
            if (!(window.navigator?.onLine)) {
                return (<div style={{ height: "100vh" }} className="d-flex align-items-center justify-content-center">
                    <div className="col-12 mt-4 text-center" style={{ color: "black !important" }}>
                        <WifiOff size={50} />
                        <br /><br /><br />
                        <h3 >Conexión inestable</h3>
                        <small>Verifica que estés conectado a una red</small>
                        <br /><br />
                        <br /><br />
                        <button className="btn btn-warning text-white" onClick={() => { this.reloadChildren() }}>Reintentar</button>
                    </div>
                </div>)
            }
            return (
                <div style={{ height: "100vh" }} className="d-flex align-items-center justify-content-center">
                    <style>{"@media screen and (min-width: 500px){.img-cont-ng{height: 100%}}"}</style>
                    <div style={{ "height": "90%", "width": "90%", "borderRadius": "15px" }} className="bg-light p-4 text-center">

                        <div style={{ "height": "15%" }} className="row">
                            <div className="col-12 text-muted mt-4">
                                <h4 >Ops, Parece que hubo un error en la aplicación</h4>
                            </div>
                        </div>
                        <div style={{ "height": "70%" }} className="row d-flex align-items-center">
                            <div className="col-12 p-3 opacity-05-infinity img-cont-ng" style={{ "maxHeight": "100%" }}>
                                <img alt="Error" src={require("./../../img/undraw_bug_fixing_oc7a.svg")} style={{ maxHeight: "100%", "maxWidth": "100%" }} />
                            </div>
                        </div>
                        <div style={{ "height": "15%" }} className="row mt-4 pt-2">
                            <div className="col-12">
                                {this.state.loading !== null ? <>
                                    <p><b>Reportando error</b></p>
                                    <div className="progress bg-white mb-4">
                                        <div className="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" aria-valuenow={this.state.loading} aria-valuemin="0" aria-valuemax="100" style={{ width: `${this.state.loading}%` }}>{this.state.loading}%</div>
                                    </div>
                                </>
                                    :
                                    <>
                                        <p>Recargaremos automaticamente en <big><b>{this.state.timer}</b></big></p>
                                        <a className="btn btn-warning btn-sm text-white" role="button" onClick={() => window.location.reload(true)}>Recargar ahora</a>
                                    </>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            )
        }

        return this.props.children;
    }
}

export default ErrorHandler;
