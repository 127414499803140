import {
    SET_CAMPAIGN,
    SET_CAMPAIGN_VIWED
} from "../action/campaignAction";

const defaultState = {
    campaign: JSON.parse(localStorage.getItem("portal/campaign") || "[]").filter(c => !(c.omit)),
    campaignViwed: false
}

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_CAMPAIGN:
            localStorage.setItem("portal/campaign", JSON.stringify(action.payload));
            return { ...state, campaign: action.payload }
        case SET_CAMPAIGN_VIWED:
            return { ...state, campaignViwed: action.payload }
        default:
            return { ...state }
    }
}

export default reducer;