/**
 * IF A PATH END WITH /, IS BECAUSE HAS SOME PARAM(s)
 */

//ROOT PATHS
export const MANAGEMENT_ROOT_PATH = "/management"
export const PAGES_ROOT_PATH = "/pages"
export const LOGIN_ROOT_PATH = "/login"

// Management paths
export const CREATE_ACCOUNT_PATH = `${MANAGEMENT_ROOT_PATH}/crearcuenta`;
export const RECOVERY_PASSWORD_PATH = `${MANAGEMENT_ROOT_PATH}/recuperarcontrasena/`; // :user_email_param

// Pages paths
export const HOME_PAHT = `${PAGES_ROOT_PATH}/inicio`;
