import {
    SET_SIDEBAR_OPTIONS,
    SET_CALENDAR_EVENTS,
    SET_WEATHER_LOCATION,
    SET_UNIEVENTS,
    SET_BANNERS,
    SET_NEWS,
    SET_THEME,
    SET_SIDEBAR,
    SET_PORTADA
} from "../action/generalAction";

const defaultState = {
    sidebarOptions: null,
    sidebarFastPaths: null,
    calendarEvents: null,
    weatherLocation: null,
    banners: null,
    news: {
        inter: null,
        invest: null,
        generals: null,
        psocial: null
    },
    filteredNews: null,
    events: null,
    theme: localStorage.getItem("portal/theme") || "light-theme",
    portada: localStorage.getItem("portal/portada"),
    sidebar: localStorage.getItem("portal/sidebar") || "default"
}

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_SIDEBAR_OPTIONS:
            return { ...state, sidebarOptions: action.payload._groups, sidebarFastPaths: action.payload._paths }
        case SET_BANNERS:
            return { ...state, banners: action.payload }
        case SET_CALENDAR_EVENTS:
            return { ...state, calendarEvents: action.payload }
        case SET_NEWS:
            return {
                ...state,
                news: {
                    ...state.news,
                    [action.payload.type]: action.payload.items,
                },
                filteredNews: !(state.filteredNews) ? action.payload.items : state.filteredNews
            }
        case SET_WEATHER_LOCATION:
            return { ...state, weatherLocation: action.payload }
        case SET_UNIEVENTS:
            return { ...state, events: action.payload }
        case SET_THEME:
            // document.body.setAttribute("data-theme", action.payload);
            localStorage.setItem("portal/theme", action.payload);
            return { ...state, theme: action.payload }
        case SET_PORTADA:
            localStorage.setItem("portal/portada", action.payload);
            return { ...state, portada: action.payload }
        case SET_SIDEBAR:
            // document.body.setAttribute("data-sidebar", action.payload);
            localStorage.setItem("portal/sidebar", action.payload);
            return { ...state, sidebar: action.payload }
        default:
            return { ...state }
    }
}

export default reducer;