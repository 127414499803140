import React, { Suspense, lazy } from 'react';
import lazyLoaderComponents from './../services/lazyLoaderComponents';
import { connect } from 'react-redux';
import {
    HashRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import Preloader from '../components/UI/Preloader';
import { MANAGEMENT_ROOT_PATH, PAGES_ROOT_PATH, LOGIN_ROOT_PATH } from '../services/internalPathsService';

const UserManagement = lazy(() => lazyLoaderComponents(() => import('./UserManagement')))
const User = lazy(() => lazyLoaderComponents(() => import('./User')))
const LoginScreen = lazy(() => lazyLoaderComponents(() => import('./UserManagement/Login')))

function Root(props) {
    const theme = localStorage.getItem("portal/theme");
    const sidebar = localStorage.getItem("portal/sidebar");
    if (!!(theme) && !!(props.user)) {
        document.body.setAttribute("data-theme", theme);
    }
    if (!!(sidebar)) {
        document.body.setAttribute("data-sidebar", sidebar);
    }
    return (
        <Router>
            <Suspense fallback={<div><Preloader /></div>}>
                <Switch>
                    {!(props.user) ?
                        <Route exact path={LOGIN_ROOT_PATH} component={LoginScreen} />
                        :
                        <Route path={PAGES_ROOT_PATH} component={User} />
                    }
                    <Redirect exact from="/recovery" to={`${MANAGEMENT_ROOT_PATH}/recuperarcontrasena`} />
                    <Route path={MANAGEMENT_ROOT_PATH} component={UserManagement} />
                    <Route path="*">
                        {!(props.user) ?
                            <Redirect exact to={LOGIN_ROOT_PATH} />
                            :
                            <Redirect exact to={PAGES_ROOT_PATH} />
                        }
                    </Route>
                </Switch>
            </Suspense>
            <footer className='bg-light-gray'>
                <div className='text-center text-secondary my-2' style={{opacity: "0.7"}}>
                    <small className='d-inline-block'>Corporación Universitaria Rafael Núñez&nbsp;|&nbsp;</small>
                    <small className='d-inline-block'>Institución Universitaria&nbsp;|&nbsp;</small>
                    <small className='d-inline-block'>Vigilada Mineducación</small>
                </div>
            </footer>
        </Router>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user.userInfo
    }
}

export default connect(mapStateToProps)(Root);