import crypto from 'crypto';

// ENCRYPT A MESSAGE
export const ENCRYPT_HASH_CODE = (message) => crypto.createHmac('sha256', CRYPTO_SECRET_KEY).update(message).digest('hex');

//Months and days
export const MONTHS = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
export const DAYS = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sabado"]

//Weather
export const weatherNameEq = {
  '01d': 'Soleado', //Clear sky day
  '01n': 'Despejado', //Clear sky night
  '02d': 'Par. Nublado', //Few cloud day 
  '02n': 'Par. Nublado', //Few cloud night
  '03d': 'Nublado', //scattered clouds day
  '03n': 'Nublado', //scattered clouds night
  '04d': 'Nublado', //Broken clouds day
  '04n': 'Nublado', //Broken clouds Night
  '09d': 'Aguacero', //shower rain day
  '09n': 'Aguacero', //shower rain night
  '10d': 'Lluvia', // Rain day
  '10n': 'Lluvia', // Rain night
  '11d': 'Tormenta', // Thunderstorm day
  '11n': 'Tormenta', // Thunderstorm night
  '13d': 'Sereno', // Snow day
  '13n': 'Sereno', // Snow night
  '50d': 'Nublado', //Mist day
  '50n': 'Nublado' //Mist night
}

//Eq. to weather template style
export const weatherIconEq = {
  '01d': 'sun', //Clear sky day
  '01n': 'sun', //Clear sky night
  '02d': 'sun cloud', //Few cloud day 
  '02n': 'sun cloud', //Few cloud night
  '03d': 'cloudy', //scattered clouds day
  '03n': 'cloudy', //scattered clouds night
  '04d': 'cloud', //Broken clouds day
  '04n': 'cloud', //Broken clouds Night
  '09d': 'rain-strong', //shower rain day
  '09n': 'rain-strong', //shower rain night
  '10d': 'rain', // Rain day
  '10n': 'rain', // Rain night
  '11d': 'thunderstorm', // Thunderstorm day
  '11n': 'thunderstorm', // Thunderstorm night
  '13d': 'rain', // Snow day
  '13n': 'rain', // Snow night
  '50d': 'cloudy', //Mist day
  '50n': 'cloudy' //Mist night
}

/**
 * 
 * @param {string} base64 
 * @param {float} quality between 0 and 1
 */
export const compressImage = (base64, quality = 0.7) => {
  const canvas = document.createElement('canvas')
  const img = document.createElement('img')

  return new Promise((resolve, reject) => {
    img.onload = function () {
      let width = img.width
      let height = img.height
      const maxHeight = 200
      const maxWidth = 200

      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height *= maxWidth / width))
          width = maxWidth
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width *= maxHeight / height))
          height = maxHeight
        }
      }
      canvas.width = width
      canvas.height = height

      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0, width, height)

      resolve(canvas.toDataURL('image/jpeg', quality))
    }
    img.onerror = function (err) {
      reject(err)
    }
    img.src = base64
  })
}

/**
 * 
 * @param {string} dataurl bs64 object string 
 * @param {string} filename 
 */
export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}



/**
 * ============================================================================
 * PRODUCTION ENVIRONMENT CONSTANS
 * ============================================================================
 */

// API KEY FOR RECAPTCHA 
export const RECAPTCHA_API_KEY_V3 = `6LdKUNUZAAAAAPVx2_bT1CtIm3t4Kj9437hReFoa`;
export const RECAPTCHA_API_KEY = `6LeB0W4UAAAAACXqVNcH-HWzYjcgCw2sY7iMtu0R`;

// Google client ID for curnvirtual domain
export const GOOGLE_CLIENT_ID_CURNVIRTUAL = "489784104983-0aj59m5r7qkmqntur44j5st3dg5ror9t.apps.googleusercontent.com";

// BASE URL DOMAIN AXIS 
export const AXIS_BASE_URL = `https://axis.uninunez.edu.co/`;

//APIs URLs
export const API_LDAP = `${AXIS_BASE_URL}apildap/api/`;
export const API_PORTAL = `${AXIS_BASE_URL}apiportal/api/`;

// API PROCESOS BASE URL
export const CRYPTO_SECRET_KEY = `unicurn`;

/**
 * ============================================================================
 * DEVELOPMENT ENVIRONMENT CONSTANS
 * ============================================================================
 */



