import Axios from 'axios';
import { AXIOS_REQUEST } from './../../services/axiosService';
import { UI_MENU, GEOLOCATION_POST, WEATHER_POST, RSS_POST, UI_MODULES } from './../../services/endPointService';
import xmlToJsonService from './../../services/xmlToJsonService';
/*==============================*
*        ACTIONS CONSTANTS
*==============================*/
export const SET_SIDEBAR_OPTIONS = "SET_SIDEBAR_OPTIONS";
export const SET_CALENDAR_EVENTS = "SET_CALENDAR_EVENTS";
export const SET_WEATHER_LOCATION = "SET_WEATHER_LOCATION";
export const SET_UNIEVENTS = "SET_UNIEVENTS";
export const SET_BANNERS = "SET_BANNERS";
export const SET_NEWS = "SET_NEWS";
export const GET_NEWS = "GET_NEWS";
export const SET_THEME = "SET_THEME";
export const SET_PORTADA = "SET_PORTADA";
export const SET_SIDEBAR = "SET_SIDEBAR";

/*==============================*
*        ACTIONS FUNCTIONS
*==============================*/
export const setSidebarOptions = (payload) => ({ payload, type: SET_SIDEBAR_OPTIONS })
export const setCalendarEvents = (payload) => ({ payload, type: SET_CALENDAR_EVENTS })
export const setWeatherLocation = (payload) => ({ payload, type: SET_WEATHER_LOCATION })
export const setUnievents = (payload) => ({ payload, type: SET_UNIEVENTS })
export const setBanners = (payload) => ({ payload, type: SET_BANNERS })
export const setNews = (payload) => ({ payload, type: SET_NEWS })
export const setTheme = (payload) => ({ payload, type: SET_THEME })
export const setPortada = (payload) => ({ payload, type: SET_PORTADA })
export const setSidebar = (payload) => ({ payload, type: SET_SIDEBAR })

/*==============================*
*        ASYNC FUNCTIONS
*==============================*/

/**
 * Function to get menu options
 */
export const getMenuOptions = () => (dispatch) => AXIOS_REQUEST(UI_MENU)

/**
 * Function to get weather location
 * @param {number} latitud 
 * @param {number} longitud 
 */
export const getWeatherLocation = (latitud, longitud) => (dispatch) =>
    AXIOS_REQUEST(GEOLOCATION_POST, "post", { latitud, longitud }, false).then(resp => {
        let { address } = resp;
        const location = {
            city: address.city || address.county || address.suburb,
            country: address.country,
            village: address.village || address.state,
            neighbourhood: address.neighbourhood
        }
        return AXIOS_REQUEST(WEATHER_POST, "post", { latitud, longitud }, false).then(resp2 => {
            let { weather, main } = resp2;
            let h = new Date();
            main.temp = (main.temp - 273.14).toFixed(0);
            main.feels_like = (main.feels_like - 273.14).toFixed(0);
            main.time = h.getHours() >= 6 && h.getHours() < 18 ? "day" : "night";
            dispatch(setWeatherLocation({ weather: { ...weather[0], ...main }, location }))
        })
    })

/**
 * Get uninunez events 
 */
export const getUnievents = () => (dispatch) =>
    AXIOS_REQUEST(RSS_POST, "post", { url: "https://www.uninunez.edu.co/?option=com_jevents&task=modlatest.rss&format=feed" }, false).then(resp => {
        resp = xmlToJsonService(resp, true)
        dispatch(setUnievents(resp))
        return resp;
    })

/**
 * Get banners 
 */
export const getBanners = () => (dispatch) =>
    AXIOS_REQUEST(UI_MODULES)
        .then(resp => {
            let b = resp.data.modules.map(i => {
                i.params_module = JSON.parse(i.params_module)
                return i
            })
            dispatch(setBanners(b))
            return b;
        })
        .catch(err => dispatch(setBanners([])))

/**
 * Get news 
 * @param {string} type {generals, psocial, invest, inter}
 */
export const getNews = (type = "generals") => (dispatch) => {
    switch (type) {
        case "generals":
            return AXIOS_REQUEST(RSS_POST, "post", { url: "https://www.uninunez.edu.co/noticiascurn/246-newsgenerales/?format=feed&type=rss" }, false).then(resp => {
                resp = xmlToJsonService(resp, true)
                dispatch(setNews({ items: resp, type }))
                return resp;
            })
        case "psocial":
            return AXIOS_REQUEST(RSS_POST, "post", { url: "https://www.uninunez.edu.co/noticiascurn/244-newsps/?format=feed&type=rss" }, false).then(resp => {
                resp = xmlToJsonService(resp, true)
                dispatch(setNews({ items: resp, type }))
                return resp;
            })
        case "invest":
            return AXIOS_REQUEST(RSS_POST, "post", { url: "https://www.uninunez.edu.co/noticiascurn/243-newsinvestigacion/?format=feed&type=rss" }, false).then(resp => {
                resp = xmlToJsonService(resp, true)
                dispatch(setNews({ items: resp, type }))
                return resp;
            })
        case "inter":
            return AXIOS_REQUEST(RSS_POST, "post", { url: "https://www.uninunez.edu.co/noticiascurn/245-newsori/?format=feed&type=rss" }, false).then(resp => {
                resp = xmlToJsonService(resp, true)
                dispatch(setNews({ items: resp, type }))
                return resp;
            })
        default:
            let urls = [
                "https://www.uninunez.edu.co/noticiascurn/238-newsfcca/?format=feed&type=rss",
                "https://www.uninunez.edu.co/noticiascurn/239-newsfcs/?format=feed&type=rss",
                "https://www.uninunez.edu.co/noticiascurn/240-newsfcsh/?format=feed&type=rss",
                "https://www.uninunez.edu.co/noticiascurn/241-newsfia/?format=feed&type=rss"
            ]

            urls = urls.map(url => AXIOS_REQUEST(RSS_POST, "post", { url }, false));

            return Axios.all(urls).then(resp => {
                let item = resp.reduce((p, c) => {
                    let it = xmlToJsonService(c, true);
                    let { item } = it?.rss.channel;
                    return [...p, ...item]
                }, []);

                let rss = { rss: { channel: { item } } }
                dispatch(setNews({ items: rss, type }));
                return rss;
            })

        // return AXIOS_REQUEST(RSS_POST, "post", { url: "https://www.uninunez.edu.co/noticiascurn/245-newsori/?format=feed&type=rss" }, false).then(resp => {
        //     resp = xmlToJsonService(resp, true)
        //     dispatch(setNews({ items: resp, type }))
        //     return resp;
        // })
    }

}


//   private eve : string = "https://www.uninunez.edu.co/?option=com_jevents&task=modlatest.rss&format=feed";

//   private fcca: string = ;
//   private fcs: string = ;
//   private fcsh: string = ;
//   private fia: string = ;
