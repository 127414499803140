import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// Reducers list
import generalReducer from './reducer/generalReducer'
import userReducer from './reducer/userReducer'
import campaignReducer from './reducer/campaignReducer'
import { LOGOUT, SET_UNAUTHORIZED } from './action/userAction';

// Create deducer all in one
const allReducers = combineReducers({
    general: generalReducer,
    user: userReducer,
    campaigns: campaignReducer
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT || (action.type === SET_UNAUTHORIZED && action.payload === true)) {
        state = undefined;
    }
    return allReducers(state, action)
}

// Config Middleware for async actions
const thunkHandler = applyMiddleware(thunk);
const composeEnhancers = (typeof window !== 'undefined' && /localhost/.test(window.location.href) && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)

// Create the store
const store = createStore(rootReducer, composeEnhancers(thunkHandler))

export default store;
