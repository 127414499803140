import React from 'react';
import { render } from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import store from './store';
import './bootstrap.min.css';
import './../node_modules/intl-tel-input/build/css/intlTelInput.css';
import './index.css';
import ErrorHandler from './components/ErrorHandler';
import Root from './screens/Root';
import { AXIOS_REQUEST } from './services/axiosService';

console.log("%cADVERTENCIA: \nEsta es una función del navegador destinada a desarrolladores. \nSi intenta hacer algo aquí para habilitar alguna función o \"piratear\" caracteristicas del sitio, podrías perder el acceso al mismo."
  , "color:red;font-size:20px;background-color: yellow;font-weight: bold;");

AXIOS_REQUEST(null, 'get', null, false, "https://axis.uninunez.edu.co/apiaxis/api/mantenimiento/portal")
  .then(resp => {
    if (!!(resp) && typeof resp === 'string') {
      window.location = resp;
    }
  }).catch(()=>{})

render(
  <ErrorHandler>
    <CookiesProvider>
      <Provider store={store}>
        <Root />
      </Provider>
    </CookiesProvider>
  </ErrorHandler>
  ,
  document.getElementById('root')
);

