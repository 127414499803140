import { API_PORTAL, API_LDAP } from './constantsService';

export const ERROR_REPORTING_URL = "https://axis.uninunez.edu.co/apildap/api/log/errorwrite";

/**
 * If an endpoint finish with a "/", is because need a url param
 * 
 * If an enpoint not finish with a sufix, is because it's method is GET
 */

//* ========================== *//
//*        API LDAP          *//
//* ========================== *//
export const LDAP_VERIFICATION = `${API_LDAP}ldap/verification/`; //Devuelve los métodos de recuperación de una cuenta
export const AUTHDB_RECAPTCHA_POST = `${API_LDAP}authdb/recaptcha`; //Valida captcha
export const LDAP_PROVISIONING_VALIDATEUSER_POST = `${API_LDAP}ldap/provisioning/validateuser`; //Validar persona para aprovisionar cuenta de usuario --> CREA TOKEN
export const LDAP_PROVISIONING_VALIDATEDATA_POST = `${API_LDAP}ldap/provisioning/validatedata`; //Permite generar un hash del código de validación enviado
export const LDAP_PROVISIONING_DATA = `${API_LDAP}ldap/provisioning/data`; //Obtiene los datos del usuario que va aprovisionar la cuenta
export const LDAP_PROVISIONING_ADD_POST = `${API_LDAP}ldap/provisioning/add`; //Add usuario por auto-aprovisionamiento en LDAP y GSUITE
export const LDAP_VERIFICATION_VALIDATE_POST = `${API_LDAP}ldap/verification/validate`; //De acuerdo al método seleccionado se envía código de validación
export const LDAP_VERIFICATION_VERIFY_POST = `${API_LDAP}ldap/verification/verify`;  //Se verifica el código de validación y otros datos --> CREA TOKEN
export const LDAP_EDIT_PWD_POST = `${API_LDAP}ldap/edit/pwd`;  //Actualizar contraseña
export const LDAP_EDIT_RECOVERY_TEL_POST = `${API_LDAP}ldap/edit/recoverytel`;  //Actualizar teléfono de recuperacion
export const LDAP_EDIT_RECOVERY_MAIL_POST = `${API_LDAP}ldap/edit/recoverymail`;  //Actualizar email de recuperacion
export const AUTHDB_AUTH2FACTOR_REGISTER_POST = `${API_LDAP}authdb/auth2factor/register`; //Habilita la autenticación 2ble factor
export const AUTHDB_AUTH2FACTOR_VERIFY_POST = `${API_LDAP}authdb/auth2factor/verify`; //Verificar codigo de autenticación doble factor
export const LDAP_ACCOUNT = `${API_LDAP}ldap/account`;  //Buscar cuenta a través del LDAP
export const LDAP_ACCOUNT_AUTHTOKEN_POST = `${API_LDAP}ldap/account/authtoken`;  // Genera token de 10 minutos para api ldap {correo, clave}
export const GSUITE_DIRECTORY = `${API_LDAP}gsuite/directory/`;  //Obtener informacón de cuenta través del LDAP de GSUITE para inicio de sesión


//* ========================== *//
//*        API PORTAL          *//
//* ========================== *//
export const ACCOUNT_AUTH_POST = `${API_PORTAL}auth/autenticar`;  //Autentica la cuenta para inicio de sesión
export const ACCOUNT_AUTH_ALT_POST = `${API_PORTAL}auth/autenticar/alt`;  //Autentica la cuenta para inicio de sesión con otros medios
export const UI_MENU = `${API_PORTAL}ui/menu`; //Obtiene el menú de acuerdo al rol (Necesita token)
export const UI_MODULES = `${API_PORTAL}ui/modules`; //Los modulos a mostrar en el home
export const UI_GET_PAGE = `${API_PORTAL}ui/page/`; //Los modulos a mostrar en el home /id_page
export const WEATHER_POST = `${API_PORTAL}utils/clima`; //Obtiene el clima {latitud, longitud}
export const GEOLOCATION_POST = `${API_PORTAL}utils/geo`; //Obtiene la descripción de la ubicación {latitud, longitud}
export const RSS_POST = `${API_PORTAL}utils/www`; //Obtiene la info de un rss {url}
export const MYHORARY = `${API_PORTAL}acad/horario`; //Obtiene el horario de un usuario
export const CALIFICATIONS = `${API_PORTAL}acad/parciales`; //Obtiene las calificaciones de un usuario

export const DEPARTAMENTOS = `${API_PORTAL}aux/departamentos`; //Obtiene departamentos de colombia
export const CIUDADES = `${API_PORTAL}aux/ciudades/`; //Obtiene ciudades de un departamento (Requiere codigo departamento)
export const ADD_EXPEDICION = `${API_PORTAL}aux/expedicion`; //REgistra fecha y lugar de expedicion de un documento
