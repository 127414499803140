/*==============================*
*        ACTIONS CONSTANTS
*==============================*/
export const SET_CAMPAIGN = "SET_CAMPAIGN";
export const SET_CAMPAIGN_VIWED = "SET_CAMPAIGN_VIWED";

/*==============================*
*        ACTIONS FUNCTIONS
*==============================*/
export const setCampaign = (payload) => ({ payload, type: SET_CAMPAIGN })
export const setCampaignViwed = (payload) => ({ payload, type: SET_CAMPAIGN_VIWED })

/*==============================*
*        ASYNC FUNCTIONS
*==============================*/
